import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { loginUserInfo } from '@/store/loginUserInfo'
import uploadToOSS from '@/utils/upload'
import { Button, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditModal from './editModal'
import styles from './index.module.css'

const propsMap = {
  username: 'nickName',
  email: 'E-mail',
}

const ProfileSettings = () => {
  const { userInfo } = useSelector(loginUserInfo)
  const [editInfoProps, setEditInfoProps] = useState({
    username: userInfo.username,
    email: userInfo.email,
  })
  const [currentKey, setCurrentKey] = useState('')
  const [realKey, setRealKey] = useState('')
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const editModalRef = useRef(null)
  const editUserInfo = (key) => {
    setCurrentKey(propsMap[key])
    setRealKey(key)
    editModalRef.current.open()
  }
  const onChange = (value) => {
    setValue(value)
  }
  const handleSave = () => {
    setEditInfoProps({ ...editInfoProps, [realKey]: value })
    editModalRef.current.close()
  }
  const handleAvatarChange = (e) => {
    console.log(e.target.files)
    const files = e.target.files
    const file = files[0]
    uploadToOSS(file)
  }

  return (
    <div className="bg-[#131313] text-white min-h-screen p-6">
      <EditModal
        currentKey={currentKey}
        currentValue={editInfoProps[currentKey]}
        ref={editModalRef}
        onChange={onChange}
        onSave={handleSave}
      />
      <Header />
      <div className="flex">
        <Sidebar isMobile={true} from="profile" />
        <div className="w-[350px] sm:w-[450px] lg:w-[640px] relative bg-zinc-900 rounded-[10px] border border-[#282828] mx-auto mt-20 p-6">
          {/* Profile Settings Header */}
          <Typography variant="h4" className="text-center mb-6">
            Profile Settings
          </Typography>

          {/* Profile Info Section */}
          <div className="justify-between items-center w-full lg:inline-flex p-4 max-w-full">
            {/* Avatar */}
            <div className="flex items-center mb-4 md:mb-0">
              <div className={`${styles.avatar}`}>
                <img
                  src="https://candy.ai/assets/user-icons/man-f06d242ecbaa154c05843d8e91f779c41dbef390319247606b1919b87169c74a.svg"
                  alt="profile"
                  className="w-full h-full object-cover rounded-full"
                />
                <div className={`${styles.avatarOverlay}`}>
                  <div className="w-6 h-6 bg-pink-500 rounded-full flex justify-center items-center text-white text-xs">
                    <i className="iconfont icon-edit">&#xe685;</i>
                    <input
                      type="file"
                      className="opacity-0 cursor-pointer absolute top-0 left-0 w-full h-full z-10"
                      onChange={handleAvatarChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Profile Details */}
            <div className="grid grid-cols-2 gap-x-20 gap-y-4 w-full md:w-auto text-left">
              {Object.keys(editInfoProps).map((key) => (
                <div
                  className="flex flex-col"
                  key={key}
                  onClick={() => {
                    editUserInfo(key)
                  }}
                >
                  <div className="flex items-center">
                    <Typography className="text-neutral-400 text-sm pb-1">
                      {propsMap[key]}
                    </Typography>
                    <i className="iconfont icon-edit text-white block text-xl -mt-2 ml-1 cursor-pointer">
                      &#xe685;
                    </i>
                  </div>
                  <Typography className="text-ellipsis whitespace-nowrap overflow-hidden max-w-40">
                    {editInfoProps[key]}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Danger Zone */}
      <div className="w-[350px] sm:w-[450px] lg:w-[640px] relative bg-zinc-900 rounded-[10px] border border-[#282828] mx-auto mt-8 p-3">
        <Button variant="outlined" color="error">
          Delete account
        </Button>
      </div>
    </div>
  )
}

export default ProfileSettings
