import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { forwardRef, useImperativeHandle, useState } from 'react'

// 自定义模糊弹窗
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(26, 31, 46, 0.8)',
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    borderRadius: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
    padding: theme.spacing(1),
    color: '#fff',
    overflow: 'visible',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}))

const EditModal = forwardRef((props, ref) => {
  const { currentKey, currentValue, onChange } = props
  const [open, setOpen] = useState(false)
  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }))

  return (
    <BlurDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Edit {currentKey}</DialogTitle>
      <DialogContent className="w-full">
        <TextField
          placeholder={currentKey}
          value={currentValue}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              border: '1px solid rgba(236, 72, 153, 1)',
            },
            '& input': { color: 'white', width: '300px' },
          }}
        />
        <div className="flex justify-center mt-4">
          <Button
            className="w-full"
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Save Changes
          </Button>
        </div>
      </DialogContent>
    </BlurDialog>
  )
})

export default EditModal
