import { setShowLogin } from '@/store/loginUserInfo'
import { Compass, MessageCircle, Users } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function Sidebar(props) {
  const { isMobile, from } = props
  const navigate = useNavigate()
  const isGuest = useSelector((state) => state.loginUserInfo.isGuest)
  const dispatch = useDispatch()
  const [navItems, setNavItems] = useState([
    { name: 'Explore', icon: Compass, link: '/' },
    { name: 'Chat', icon: MessageCircle, link: '/chat/9' },
    { name: 'Become Premium', icon: Users },
  ])
  useEffect(() => {
    setNavItems(
      navItems.map((item) => ({ ...item, active: item.link === window.location.pathname })),
    )
  }, [window.location.pathname])
  return (
    <aside
      className={`bg-[#131313] p-6 top-16 h-full overflow-y-auto ${from !== 'chat' ? 'fixed' : ''} border-[#363636] border-r z-50`}
    >
      <nav className="space-y-4">
        {navItems.map((item) => {
          const Icon = item.icon
          return (
            <a
              onClick={() => {
                if (item.name === 'Explore') {
                  navigate('/')
                } else {
                  if (isGuest) {
                    dispatch(setShowLogin(true))
                  } else {
                    navigate(item.link)
                  }
                }
              }}
              key={item.name}
              target="_blank"
              className="flex items-center text-sm font-medium  h-[40px] w-full hover:bg-zinc-700 rounded-[10px] border border-white border-opacity-10 justify-start px-3 cursor-pointer"
            >
              <Icon
                className="h-5 w-5 text-white"
                style={{ color: `${item.active ? '#E91E63' : ''}` }}
              />
              {!isMobile && (
                <span className="ml-3" style={{ color: `${item.active ? '#E91E63' : ''}` }}>
                  {item.name}
                </span>
              )}
            </a>
          )
        })}
      </nav>
    </aside>
  )
}
