import { getRecommendedUsers } from '@/service/api'
import { loginUserInfo } from '@/store/loginUserInfo'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'

const RecommendedList = () => {
  const [users, setUsers] = useState([])
  const loginInfo = useSelector(loginUserInfo)
  const { userInfo } = loginInfo
  const navigate = useNavigate()
  useEffect(() => {
    const { user } = userInfo
    const start = async () => {
      const res = await getRecommendedUsers(user.id)
      if (res?.data) {
        setUsers(res.data)
      }
    }
    start()
  }, [])

  return (
    <div className={styles.list}>
      {users?.map((user) => (
        <div
          key={user.id}
          className={styles.listItem}
          onClick={() => {
            navigate(`/chat/${user.id}`)
          }}
        >
          <img
            src={
              user.avatar ||
              'https://cdn.candy.ai/cdn-cgi/image/format=webp,quality=80/https://cdn.candy.ai/76756996-b9b30f95-4bdd-4954-86d3-eca56ccab31e'
            }
            alt={user.displayName}
            className="rounded-lg profile-image first-image object-cover object-top h-[400px] max-h-[400px] w-full max-w-[300px]"
          />
          <div className="absolute top-2 left-2">
            {user.isNew ? (
              <div className="bg-pink-600 text-white text-xs font-bold px-2 py-1 rounded">New</div>
            ) : user.isHot ? (
              <div className="bg-pink-600 text-white text-xs font-bold px-2 py-1 rounded">Hot</div>
            ) : null}
          </div>
          <div className="absolute bottom-0 text-left px-3 md:px-4 py-3 font-medium z-20">
            <span className="text-white text-xl font-bold"> {user.displayname}</span>
            {user.age && (
              <span className="text-gray-100 font-normal block text-sm md:text-sm opacity-[0.8] py-2">
                , {user.age}
              </span>
            )}
            <p className="text-gray-100 font-normal block text-sm md:text-sm opacity-[0.8] py-2">
              An alternative goth girl who recently dropped...
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RecommendedList
