import request from './request'

const BASE_URL = '/api/model-server/v1'
export const login = (params) =>
  request(`${BASE_URL}/login`, {
    method: 'POST',
    data: params,
  })

export const sendVerificationCode = (params) =>
  request(`${BASE_URL}/send-verification-code`, {
    method: 'POST',
    data: params,
  })

export const register = (params) =>
  request(`${BASE_URL}/register`, {
    method: 'POST',
    data: params,
  })

export const getUserInfo = () =>
  request(`${BASE_URL}/me`, {
    method: 'GET',
  })

export const prefenceTags = () =>
  request(`${BASE_URL}/get_all_preference`, {
    method: 'GET',
  })

export const getRecommendedUsers = (uid) =>
  request(`${BASE_URL}/recommendlist/${uid}`, {
    method: 'GET',
  })

export const saveUserPreference = (params) =>
  request(`${BASE_URL}/set_user_info`, {
    method: 'POST',
    data: params,
  })

export const followUser = (params) =>
  request(`${BASE_URL}/allow_users`, {
    method: 'POST',
    data: params,
  })
export const uploadParams = () =>
  request(`${BASE_URL}/oss/get_sts_token`, {
    method: 'GET',
  })
