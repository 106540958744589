import { useState } from 'react'
import Email from './Step/email'
import Verify from './Step/Verify'

const SignupDialog = (props) => {
  const [showVerify, setShowVerify] = useState(false)
  const [signupInfo, setSignupInfo] = useState({
    email: '',
    password: '',
  })

  const callVerify = (email, password) => {
    setSignupInfo({ email, password })
    setShowVerify(true)
  }

  return showVerify ? <Verify signupInfo={signupInfo} /> : <Email callVerify={callVerify} />
}

export default SignupDialog
