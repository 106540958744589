import { useEffect } from 'react'
// import { DwavesUsersLoginwithgooglejwt } from '@/api/yapiApi/index'

export default function GoogleLogin(props) {
  // const { jumpIndex } = props
  const handleCredentialResponse = async (res) => {
    // const postCode = await DwavesUsersLoginwithgooglejwt({
    // 	code: res.credential
    // })
    const googleFirst = localStorage.getItem('Google_First')
    if (!googleFirst) {
      localStorage.setItem('Google_First', 1)
      // jumpIndex(2)
    } else {
      window.location.reload()
    }
  }
  const onClickHandler = (res) => {
    console.log(res, 'onClickHandler')
  }
  useEffect(() => {
    if (typeof google === 'object') {
      google.accounts.id.initialize({
        client_id: '1009542111163-nifq4j0ogbvthj0i9bgq93igqcf1clog.apps.googleusercontent.com',
        ux_mode: 'popup',
        // callback: handleCredentialResponse,
        // login_uri: 'http://www.vibbe.ai/',
      })
      const loginBtn = document.getElementById('google_login')
      google.accounts.id.renderButton(loginBtn, {
        click_listener: onClickHandler,
        text: 'continue_with',
        width: 370,
        locale: 'en',
      })
      google.accounts.id.prompt((notification) => {
        console.log(notification, 'notification')
      })
    }
  }, [])
  return <div id="google_login"></div>
}
