import { Avatar } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Fragment, useEffect, useRef } from 'react'

const MsgList = ({ messageList, onScroll }) => {
  const parentRef = useRef(null)
  const virtualizer = useVirtualizer({
    count: messageList.length,
    estimateSize: () => 48,
    getScrollElement: () => parentRef.current,
    overscan: 15,
  })
  useEffect(() => {
    parentRef.current.scrollTop = 100000
  }, [messageList])

  const md2html = (item) => {
    if (item.msgType === 'text') {
      const singleStarPattern = /\*([^*]+)\*|<Greeting>(.*?)<\/Greeting>/g
      const doubleStarPattern = /\*\*([^*]+)\*\*/g
      let usedText = item.text
        .replace(doubleStarPattern, `<span class="text-white font-bold">$1</span>`)
        .replace(singleStarPattern, `<span class="text-[#a0a0a0] italic">$1</span>`)
      return usedText
    }
  }
  useEffect(() => {
    parentRef.current?.addEventListener('scroll', onScroll)
    return () => {
      parentRef.current?.removeEventListener('scroll', onScroll)
    }
  }, [])

  const virtualItems = virtualizer.getVirtualItems()

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: 600,
        overflowY: 'auto',
        contain: 'strict',
      }}
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${virtualItems[0]?.start}px)`,
          }}
        >
          {virtualItems?.map((virtual) => {
            const { index } = virtual
            const item = messageList[index]
            return (
              <Fragment key={virtual.index}>
                <div
                  className={`flex mb-6 ${item.action === 'send' ? 'justify-end' : 'justify-start'}`}
                  data-index={virtual.index}
                  ref={virtualizer.measureElement}
                >
                  {item.action === 'reply' && (
                    <Avatar
                      className="mr-3"
                      sx={{ width: 30, height: 30 }}
                      src="https://via.placeholder.com/40"
                    />
                  )}

                  {item.msgType === 'text' && (
                    <div
                      className={`max-w-xs p-3 text-white rounded-lg ${
                        item.action === 'send' ? 'bg-[#b8962f] rounded-br-none' : 'rounded-bl-none'
                      }`}
                      dangerouslySetInnerHTML={{ __html: md2html(item) }}
                    ></div>
                  )}
                  {item.action === 'send' && (
                    <Avatar
                      className="ml-3"
                      sx={{ width: 30, height: 30 }}
                      src="https://via.placeholder.com/40"
                    />
                  )}
                </div>
                {item.lastDay && (
                  <div className="flex items-center gap-4 mb-6">
                    <div
                      className="flex-1 h-[1px]"
                      style={{
                        background:
                          'linear-gradient(270deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)',
                      }}
                    ></div>
                    <div className="text-white">or sign in with</div>
                    <div
                      className="flex-1 h-[1px]"
                      style={{
                        background:
                          'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%)',
                      }}
                    ></div>
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default MsgList
