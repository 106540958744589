import { prefenceTags } from '@/service/api'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'

const StyledButton = styled(Button)({
  backgroundColor: '#fff',
  color: '#ff69b4',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  width: '100%',
  padding: '10px',
  marginBottom: '10px',
})

const Interests = ({ getChoosenInfo }) => {
  const [preference, setPreference] = useState([])
  const [interests, setInterests] = useState([])

  const setSelectedStyle = (type) => {
    return {
      backgroundColor: preference.includes(type) ? '#ff69b4' : 'none',
      color: preference.includes(type) ? '#fff' : '#ff69b4',
      '&:hover': {
        backgroundColor: preference.includes(type) ? '#ff69b4' : 'none',
        color: preference.includes(type) ? '#fff' : '#ff69b4',
      },
    }
  }
  useEffect(() => {
    getChoosenInfo({ preference })
  }, [preference])

  useEffect(() => {
    const start = async () => {
      const res = await prefenceTags()
      if (res?.data) {
        setInterests(res.data)
      }
    }
    start()
  }, [])
  return (
    <div className="text-white bg-[#313947] bg-opacity-30 p-5 rounded-lg">
      <span className="font-bold block pb-4">Your Interests</span>
      <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-[400px]">
        {interests.map((interest, index) => (
          <StyledButton
            key={index}
            sx={{ ...setSelectedStyle(interest), flex: '1 1 30%' }}
            className="flex-1"
            onClick={() => setPreference([...preference, interest])}
          >
            {interest}
          </StyledButton>
        ))}
      </div>
    </div>
  )
}

export default Interests
