import '@/assets/iconfont/iconfont.css'
import { useRoutes } from 'react-router-dom'
import './App.css'
import Routes from './router'

function App() {
  const element = useRoutes(Routes)

  return <div className="App w-full h-full min-w-[1200px]">{element}</div>
}

export default App
