import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import StepOne from './Step/One'
// import StepTwo from './Step/Two'
import Notification from '@/components/Alert'
import { saveUserPreference } from '@/service/api'
import { loginUserInfo } from '@/store/loginUserInfo'
import { useSelector } from 'react-redux'
import StepFive from './Step/Five'
import StepFour from './Step/Four'
// import StepSix from './Step/Six'
import StepThree from './Step/Three'

const GuideDialog = (props) => {
  const { close } = props
  const [activeStep, setActiveStep] = useState(0)
  const loginInfo = useSelector(loginUserInfo)
  const { userInfo } = loginInfo
  const { user } = userInfo
  const [choosenInfo, setChoosenInfo] = useState({
    displayname: user.email?.split('@')[0],
    username: '',
    male: '0',
    preferencegender: 'all',
  })

  const handleBack = () => {
    setActiveStep((prev) => prev - 1)
  }
  const infoMap = {
    0: ['age'],
    1: ['displayname', 'username'],
  }
  const calculateAge = (birthDate) => {
    const today = new Date()
    const birth = new Date(birthDate)
    let age = today.getFullYear() - birth.getFullYear()
    const monthDifference = today.getMonth() - birth.getMonth()

    // 如果当前月份还没到出生月份，或者到了出生月份但还没到出生日期，则年龄减一
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--
    }
    return age
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!choosenInfo.age) {
        Notification.open('Please enter your birthdate to continue.', 'warning')
        return
      }
      if (choosenInfo.age) {
        const age = calculateAge(choosenInfo.age)
        if (age < 18) {
          Notification.open('You must be at least 18 years old to continue.', 'warning')
          return
        }
      }
    }
    const info = infoMap[activeStep]
    let [isChoosen, name] = [true, '']
    if (Array.isArray(info)) {
      info.forEach((item) => {
        if (!choosenInfo[item]) {
          isChoosen = false
          name = item
        }
      })
    }
    if (!isChoosen) {
      Notification.open(`Please choose your ${name}`, 'warning')
      return
    }
    saveUserPreference({ ...choosenInfo, id: user.id })
    setActiveStep((prev) => prev + 1)
  }

  const getChoosenInfo = (info) => {
    setChoosenInfo({ ...choosenInfo, ...info })
  }
  useEffect(() => {
    if (activeStep > components.length - 1) {
      close()
    }
  }, [activeStep])

  const components = [
    <StepOne getChoosenInfo={getChoosenInfo} />,
    <StepThree getChoosenInfo={getChoosenInfo} />,
    <StepFour getChoosenInfo={getChoosenInfo} />,
    <StepFive getChoosenInfo={getChoosenInfo} />,
    // <StepSix getChoosenInfo={getChoosenInfo} />,
  ]
  const renderStep = () => {
    return components[activeStep] || null
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography sx={{ color: '#fff' }}>{activeStep + 1}/5</Typography>
        {activeStep > 0 && (
          <IconButton onClick={handleBack} sx={{ color: '#ff69b4' }}>
            <ArrowBackIcon />
          </IconButton>
        )}
      </Box>
      {renderStep()}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        {activeStep > 0 ? (
          <Button
            variant="text"
            onClick={() => setActiveStep((prev) => prev + 1)}
            sx={{ color: '#fff' }}
          >
            Skip
          </Button>
        ) : (
          <span />
        )}
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{
            backgroundColor: '#ff69b4',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#ff4da6',
            },
          }}
        >
          {activeStep < components.length ? 'Next' : 'Done'}
        </Button>
      </Box>
    </Box>
  )
}

export default GuideDialog
