import { setRegisterSuccess } from '@/store/loginUserInfo'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Guide from './guide'

// 自定义样式的Dialog
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(28, 28, 28, 0.95)',
    borderRadius: '16px',
    padding: theme.spacing(2),
    maxWidth: '450px',
    width: '100%',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)', // 给对话框背后的内容也添加模糊效果
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

const GuideDialog = () => {
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()
  const close = () => {
    setOpen(false)
    dispatch(setRegisterSuccess(false))
  }
  return (
    <>
      <BlurDialog open={open} onClose={close}>
        <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={close}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>

        <DialogContent>
          <Guide close={close} />
        </DialogContent>
      </BlurDialog>
    </>
  )
}

export default GuideDialog
