import GoogleLogin from '@/components/GoogleLogin'
import { login } from '@/service/api'
import { addUserInfo, changeLoginStatus, loginUserInfo } from '@/store/loginUserInfo'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Signup from '../Signup'

// 自定义样式的Dialog
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(28, 28, 28, 0.95)',
    borderRadius: '16px',
    padding: theme.spacing(2),
    maxWidth: '450px',
    width: '100%',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)', // 给对话框背后的内容也添加模糊效果
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

const LoginDialog = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const { showLogin } = useSelector(loginUserInfo)
  const dispatch = useDispatch()
  const [isSignup, setIsSignup] = useState(false)
  const [emailWarning, setEmailWarning] = useState('')
  const [passwordWarn, setPasswordWarn] = useState('')

  const handlePopupStatus = () => {
    setOpen(!open)
  }
  const loginApp = async () => {
    if (!email) {
      setEmailWarning('Please enter your email')
      return
    }
    if (!password) {
      setPasswordWarn('Please enter your password')
      return
    }
    const res = await login({ email, password })
    if (res?.data) {
      const { user, imsig } = res?.data
      dispatch(addUserInfo({ user, userSig: imsig }))
      dispatch(changeLoginStatus(false))
      localStorage.setItem('TOKEN', res?.data?.access_token)
      handlePopupStatus()
    }
  }

  useEffect(() => {
    setOpen(showLogin)
  }, [showLogin])

  const handleEmailChange = (e) => {
    const val = e.target.value
    setEmail(val)
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!pattern.test(val)) {
      setEmailWarning('Invalid email address')
    } else {
      setEmailWarning('')
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        sx={{ border: '1px solid rgb(219 39 119)' }}
        className="text-white hover:bg-pink-600 hover:text-white"
        onClick={() => {
          setIsSignup(false)
          handlePopupStatus()
        }}
      >
        Login
      </Button>
      <Button
        variant="contained"
        sx={{ color: 'white' }}
        className="bg-pink-600 hover:bg-pink-700 hover:text-white"
        onClick={() => {
          setIsSignup(true)
          handlePopupStatus()
        }}
      >
        Create Free Account
      </Button>
      <BlurDialog open={open} onClose={handlePopupStatus}>
        <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={handlePopupStatus}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>

        <DialogContent>
          <Typography variant="h5" sx={{ mb: 4, color: 'white', fontWeight: 'bold' }}>
            {!isSignup ? 'Sign in' : 'Create Account'}
          </Typography>

          {!isSignup ? (
            <>
              <TextField
                fullWidth
                placeholder="E-mail"
                variant="outlined"
                onChange={handleEmailChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: '8px',
                    '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
                  },
                  '& input': { color: 'white' },
                }}
              />
              <p
                className={`text-red-500 text-sm invisible my-2 min-h-5 ${emailWarning ? '!visible' : ''}`}
              >
                {emailWarning}
              </p>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: '8px',
                    '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
                  },
                  '& input': { color: 'white' },
                }}
              />
              <p
                className={`text-red-500 text-sm invisible mt-2 min-h-5 ${passwordWarn ? '!visible' : ''}`}
              >
                {passwordWarn}
              </p>
              <Typography
                variant="body2"
                sx={{ mb: 3, color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Forgot password?
              </Typography>

              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 4, fontWeight: 'bold' }}
                onClick={loginApp}
              >
                Sign in
              </Button>
            </>
          ) : (
            <Signup />
          )}

          <div className="flex items-center gap-4 mb-6">
            <div
              className="flex-1 h-[1px]"
              style={{
                background:
                  'linear-gradient(270deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)',
              }}
            ></div>
            <div className="text-white">{!isSignup ? 'or sign in with' : 'or continue with'}</div>
            <div
              className="flex-1 h-[1px]"
              style={{
                background:
                  'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%)',
              }}
            ></div>
          </div>
          <GoogleLogin />
          {!isSignup ? (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: 'white', display: 'inline' }}>
                Don't have an account yet?{' '}
              </Typography>
              <Typography
                component="span"
                sx={{ color: '#E91E63', cursor: 'pointer' }}
                onClick={() => setIsSignup(true)}
              >
                Sign up
              </Typography>
            </Box>
          ) : (
            <>
              <div className="text-sm text-gray-400  mt-5">
                By signing up you agree to our
                <a href="#" target="_blank" className="text-blue-500 pl-1">
                  Terms of Service
                </a>{' '}
                and
                <a href="#" target="_blank" className="text-blue-500 pl-1">
                  Privacy Policy
                </a>
                , and confirm that you are at least 18 years old
              </div>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: 'white', display: 'inline', fontWeight: 'bold' }}>
                  Already have an account yet?{' '}
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: '#E91E63', cursor: 'pointer' }}
                  onClick={() => setIsSignup(false)}
                >
                  Sign in
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
      </BlurDialog>
    </>
  )
}

export default LoginDialog
