import Login from '@/components/Login'
import { getUserInfo } from '@/service/api'
import {
  addUserInfo,
  changeLoginStatus,
  loginUserInfo,
  setLoadingStatus,
} from '@/store/loginUserInfo'
import { Avatar, ListItemText, Menu, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GuideDialog from '../Guide'

export default function Header() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userInfo, resgisterSuccess } = useSelector(loginUserInfo)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const getUser = async () => {
    const token = localStorage.getItem('TOKEN')
    if (!token) return
    const res = await getUserInfo()
    const { data } = res || {}
    if (data) {
      const { user, imsig } = data
      dispatch(addUserInfo({ user, userSig: imsig }))
      dispatch(changeLoginStatus(false))
      dispatch(setLoadingStatus(false))
    }
  }
  const logout = () => {
    localStorage.removeItem('TOKEN')
    dispatch(addUserInfo({}))
    dispatch(changeLoginStatus(true))
    handleMenuClose()
    navigate('/')
    if (typeof window.google !== 'undefined') {
      google.accounts.id.disableAutoSelect()
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('TOKEN')
    if (token) {
      getUser()
    } else {
      dispatch(setLoadingStatus(false))
    }
  }, [])

  return (
    <>
      <header className="bg-[#131313] p-4 mb-4 w-full flex justify-between items-center fixed top-0 left-0 z-50 border-[#363636] border-b text-white">
        <div className="flex items-center cursor-pointer" onClick={() => navigate('/')}>
          <svg
            className="h-8 w-8 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L2 7L12 12L22 7L12 2Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 17L12 22L22 17"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 12L12 17L22 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h1 className="text-xl font-bold">
            Vibee<span className="text-pink-500">.AI</span>
          </h1>
        </div>
        {Object.keys(userInfo).length > 0 ? (
          <div className="flex items-center space-x-2 cursor-pointer">
            <Avatar src={userInfo.avatar} style={{ cursor: 'pointer' }} onClick={handleMenuOpen} />
            <div onClick={handleMenuOpen} className="hidden lg:flex lg:items-center">
              <span className="ml-2 text-sm font-semibold leading-6 text-white" aria-hidden="true">
                My Profile
              </span>
              <svg
                className="ml-2 h-5 w-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              onMouseLeave={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiMenu-list': {
                  backgroundColor: '#131313',
                  color: 'white',
                  width: '100px',
                },
              }}
            >
              <MenuItem onClick={logout}>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Login />
          </div>
        )}
        {resgisterSuccess && <GuideDialog />}
      </header>
    </>
  )
}
